<!--
* @Description: 对比营业执照
* @Author: sunchunchun
* @CreateDate: 2024/07/17 17:04:35
* @FileName: conLicenseUplod.vue
!-->
<template>
    <div class="lice-box">
        <div class="cardForm">
            <div style="display:flex">
                   <img style="width:29px;height:29px;margin-left:15px;margin-top:12px" src="../../../../assets/images/shiming-icon.png">
                   <span style="font-size:16px;margin-left:9px;margin-top:19px">实名认证</span>
            </div>
        <div class="title" style="margin-top:-2px">上传<span>营业执照</span></div>
        <div class="card-body">
            <van-uploader
                v-model="fileList"
                :before-read="beforeRead"
                :after-read="afterRead"
            >
                <img :src="imgFile != '' ? imgFile : require('@/assets/images/yingye.png')" alt="" style="width:152px;height:174px">
            </van-uploader>
        </div>
        <div class="btn">
            <van-button :loading="loading" class="next-btn" @click="onNext" round type="info"  color="#BEA588">下一步</van-button>
        </div>
    </div>
    </div>
</template>

<script>
import { fileCheck } from "@/api/user/updataPhone"
import * as imageConversion from 'image-conversion';
export default {
    name: "License",
    data () { 
        return {
            fileList: [],
            imgFile: '',
            loading: false,
            blob: ''
        }
    },
    methods: {
        // 上传前
        beforeRead(file) {
            this.fileList = []
            let regex = /(.jpg|.jpeg|.png|.bmp)$/
            if (!regex.test(file.type)) {
                this.$toast('图片格式不支持上传')
                return false
            } else {
                let isLt5M = file.size / 1024 / 1024 < 50
                if (isLt5M) {
                    return true
                } else {
                    this.$toast("上传文件不得超过50M")
                    return false
                }
            }
        },
        // 上传图片
        afterRead(file) {
            // 回显上传的图片
            this.imgFile = file.content
        },
        // 下一步
        async onNext() {
            this.loading = true
            if (this.fileList.length > 0) {
                let size = this.fileList[0].file.size / 1024 / 1024 > 4
                if (size) {
                    this.blob = await imageConversion.compressAccurately(this.fileList[0].file, 600)
                } else {
                    this.blob = this.fileList[0].file
                }
                const form = new FormData()
                form.append('file', this.blob)
                // 上传当前营业执照
                fileCheck(form, this.$route.query.userId).then(res => {
                    if (res.code === 200) {
                        this.$router.push({
                            name: "comPhoneListLogin",
                            params: {
                                comLice: 2,
                                comFace: 2,
                            }
                        })
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$toast(res.msg)
                    }
                }, error => {
                    this.loading = false
                })
            } else {
                this.$toast("请上传营业执照")
                this.loading = false
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.lice-box{
    height: 100vh;
    padding: 40px 30px;
    .title{
        width: 100%;
        text-align: left;
        margin-top: 50px;
        padding:40px 0;
        font-size: 30px;
        margin-left:33px;
    }
    .card-body{
        text-align: center;
        margin-top: 100px;
        width: auto;
        padding: 30px 100px;
        border-radius: 20px;
        background: #fff;
        img{
            width: 100%;
            height: 340px;
        }
    }
    .btn{
        margin-top: 200px;
        width: 100%;
        text-align: center;
        .next-btn{
            padding: 10px 190px;
        }
    }
}
/deep/
.van-uploader__preview{
    display: none;
    width: 31%;
}
</style>